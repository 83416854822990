<template>
  <overlay fullscreen :name="portalName">
    <search-list
      v-click-outside="handleClickOutside"
      :search-fields="['code', 'name']"
      :ordering-options="[['name']]"
      :search-placeholder="
        $t('organisms.ccl-selector.search-placeholder', {
          selectorType: $t('organisms.ccl-selector.country'),
        })
      "
      width-classes="md:w-ccl-width"
      :selected-item="selectedItem"
      :items="items"
      data-test="country-search-input"
      @search="trackSearchInput"
      @blurSearchInput="blurSearchInput"
      @search-list:selected="item => changeItem(item)"
    >
      <template #default="slotProps">
        <button-box
          no-border
          no-background
          :content="slotProps.item.name"
          :prefix-flag="slotProps.item.code"
        />
      </template>
    </search-list>
  </overlay>
</template>

<script>
import { Overlay, SearchList } from '~/components/molecules'
import { ButtonBox } from '~/components/atoms'
import { mutatePath } from '~/utils/mutatePath'

export default {
  components: {
    Overlay,
    SearchList,
    ButtonBox,
  },
  props: {
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    newSlug: {
      type: String,
      default: undefined,
    },
    portalName: {
      type: String,
      default: '',
    },
    changePath: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$gtmEnhanced.showSelector({
      type: 'country',
      eventLocation: 'in-page',
    })
  },
  methods: {
    handleClickOutside() {
      this.$emit('hide')
    },
    trackSearchInput() {
      this.$gtmEnhanced.searchInput('country')
    },
    blurSearchInput(searchTerm) {
      if (!searchTerm) return
      this.$gtmEnhanced.viewSearchResults(searchTerm, 'country')
    },
    changeItem(item) {
      this.$emit('changeItem', item)
      this.$emit('hide')
      this.$gtmEnhanced.confirmSelectType({
        type: 'country',
        item,
        eventLocation: 'in-page',
      })
      if (!this.changePath) return
      window.location.href = mutatePath(this.$route.path, {
        selectedCountry: item.code.toLowerCase(),
        selectedSlug: this.newSlug,
      })
    },
  },
}
</script>
