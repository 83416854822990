
import { PropOptions } from 'vue'
import { mapGetters } from 'vuex'

import SwitchCountry from '../../molecules/switch-country/index.vue'
import { portals } from '~/enums'
import { MainTitle, BrandCard } from '~/components/molecules'
import { Row, Column, Container } from '~/components/grid'
import { UiLink, Loader } from '~/components/atoms'
import { IPage } from '~/models/base/Page'
import { CdpContainer } from '~/components/renderers'

export default {
  components: {
    MainTitle,
    BrandCard,
    Row,
    Column,
    Container,
    UiLink,
    Loader,
    SwitchCountry,
    CdpContainer,
  },
  props: {
    brands: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    categoryPage: {
      type: Object,
      default: () => ({}),
    } as PropOptions<IPage | Record<string, unknown>>,
    hasPrefixIcon: {
      type: Boolean,
      default: true,
    },
    hasViewAllButton: {
      type: Boolean,
      default: true,
    },
    gtmTrackSectionName: {
      type: String,
      default: null,
    },
    refreshLoading: {
      type: Boolean,
      default: false,
    },
    dataTestBrandCategory: {
      type: String,
      default: '',
    },
    showCdpContainer: {
      type: Boolean,
      default: false,
    },
    idCdpContainer: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCountrySelector: false,
      countriesForProduct: [],
      selectedItem: {},
      newSlug: undefined,
    }
  },
  computed: {
    ...mapGetters('context', [
      'isGlobalMarketplace',
      'isGlobalCountry',
      'countryCode',
    ]),
    link(): string {
      return (
        this.linkText ||
        this.$t(`organisms.brand_list.link`, {
          title: this.title,
        })
      )
    },
    hasBrands() {
      return this.brands.length > 0
    },
    displayPrefixIcon() {
      return this.hasPrefixIcon
    },
    portals() {
      return portals
    },
    validBrands() {
      return this.brands.filter(
        brand =>
          (brand?.title || brand?.logo?.url || brand?.logo?.file?.url) &&
          brand?.slug
      )
    },
  },
  methods: {
    onBrandClick(brand) {
      if (!this.isGlobalCountry) return
      this.$gtmEnhanced.trackBrandClick(
        brand,
        this.gtmTrackSectionName || this.categoryPage?.slug
      )
      this.newSlug = brand.slug
      this.countriesForProduct = brand.countries
      this.showCountrySelector = true
    },
    aceCardInfo() {
      return {
        logo: {
          title: 'ace card',
          // eslint-disable-next-line global-require
          url: require('@/assets/images/logo/ace_card.svg'),
          alt: 'ace card',
        },
        slug: 'acecard',
        title: 'Ace',
      }
    },
    showAceCard() {
      const countriesToShow = ['es', 'pt']
      return (
        this.categoryPage.slug === 'payment-cards' &&
        countriesToShow.includes(this.countryCode.toLowerCase())
      )
    },
  },
}
